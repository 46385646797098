/**
 * Created by hoa on 27/03/2017.
 */

define(['simpleSlider', 'app'], function (simpleSlider, app) {


  const promoProductSlider = function () {
    const self = {};
    // keep hard-coded values together here
    const _config = {
      links: '.promoProductSlider_navBullet .promoProductSlider_navLink',
      wrapper: '.promoProductSlider_slideWrap',
      prev: '.promoProductSlider_navButton-prev',
      next: '.promoProductSlider_navButton-next',
      slide: '.promoProductSlider_slideItem',
      slideCaption: '.simpleSlider_slide-caption',
      slideNav: '.promoProductSlider_navBullet',
      loop: true,
      subscribeChannels: {
        moveSlides: 'promoProductSlider/moveSlides'
      }
    };

    // bind the parent element, load the youtube api, get the data items

    const _init = (el) => {
      self.element = el;
      self.navigation = el.querySelector(self.config.slideNav);
      self.slides = el.querySelectorAll(self.config.slide);
      self.links = el.querySelectorAll(self.config.links);
      self.wrapper = el.querySelector(self.config.wrapper);

      self.prev = el.querySelector(self.config.prev);
      self.next = el.querySelector(self.config.next);

      self.active = true;
      self.total = self.slides.length;
      self.current = 0;
      self.moveSlide(0);
      self.handleTouch();
      self.navigate();
      self.slides.forEach((slide, index) => slide.addEventListener('focusin', () => self.moveSlide(index)));
    };

    const _navigate = () => {

      if (self.navigation) {
        for (let i = 0; i < self.links.length; ++i) {
          self.slideByNav(i);
        }
      }

      if (self.prev) self.slideByButton(self.prev, -1);
      if (self.next) self.slideByButton(self.next, 1);
    };

    const _slideByNav = (index) => {
      self.links[index].addEventListener('click', (e) => {
        e.preventDefault();
        self.moveSlide(index);
      }, false);
    }

    /**
     * slideByButton
     * @param index
     */
    const _slideByButton = (element, index = 0) => {

      element.addEventListener('click', (e) => {
        e.preventDefault();
        self.moveSlide(self.current + index);
      }, false);
    };

    /**
     * moveSlide
     * @param index
     */
    const _moveSlide = (index = 0) => {

      if (self.active) {
        if (!self.config.loop && (index < 0 || index >= self.total)) return;
        else {
          if (self.config.loop && index >= self.total)
            self.current = 0;
          else if (self.config.loop && index < 0)
            self.current = self.total - 1;
          else
            self.current = index;
        }

        if (!self.slides.length) return;
        if (document.getElementsByTagName('html')[0].getAttribute('dir') === 'ltr') {
          self.wrapper.style.left = `-${self.slides[self.current].offsetLeft}px`;
        } else {
          self.wrapper.style.right = `-${self.slides[self.current].offsetWidth * self.current}px`;
        }
        app.publish('promoProductSlider/moveSlides', self.slides[self.current], index);
        self.removeCurrent();
        self.addCurrent();

      }
    };

    /**
     * removeCurrent
     */
    const _removeCurrent = () => {
      for (let i = 0; i < self.total; i++) {
        self.slides[i].classList.remove('simpleSlider-current');
        if (self.links.length) {
          self.links[i].classList.remove('simpleSlider-current');
          self.links[i].setAttribute("aria-hidden" , "true");
        }
      }
    }

    /**
     * addCurrent
     * @param index
     */
    const _addCurrent = () => {
      self.currentSlide = self.slides[self.current];
      self.slides[self.current].classList.add('simpleSlider-current');
      if (self.links.length) {
        self.links[self.current].classList.add('simpleSlider-current');
        self.links[self.current].setAttribute("aria-hidden" , "false");
      }
    };

    /**
     * handleTouch
     */
    const _handleTouch = () => {
      let touchstartX = 0;
      let touchstartY = 0;
      let touchendX = 0;
      let touchendY = 0;
      let distanceX = 0;
      let distanceY = 0;

      self.wrapper.addEventListener('touchstart', (event) => {
        touchstartX = event.touches[0].screenX;
        touchstartY = event.touches[0].screenY;
      }, {passive: true}, false);

      self.wrapper.addEventListener('touchend', (event) => {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;

        distanceX = touchendX - touchstartX;
        distanceY = touchendY - touchstartY;


        if (Math.abs(distanceX) > Math.abs(distanceY)) { //swipe left right
          if (distanceX > 50) self.swipeRight();
          if (distanceX < -50) self.swipeLeft();
        }

      }, {passive: true}, false);
    }

    /**
     * swipeRight
     */
    const _swipeRight = () => {
      self.moveSlide(self.current - 1);
    }

    /**
     * swipeLeft
     */
    const _swipeLeft = () => {
      self.moveSlide(self.current + 1);
    }

    self.config = _config;
    self.init = _init;
    self.navigate = _navigate;
    self.slideByButton = _slideByButton;
    self.slideByNav = _slideByNav;
    self.moveSlide = _moveSlide;
    self.removeCurrent = _removeCurrent;
    self.addCurrent = _addCurrent;
    self.handleTouch = _handleTouch;
    self.swipeLeft = _swipeLeft;
    self.swipeRight = _swipeRight;
    return self;

  };

  return promoProductSlider;

});
